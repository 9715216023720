<script setup lang="ts">
import { ALL_LOB } from '~/constants'
import { useAppConfiguration } from '~/stores/appConfiguration'
import { useWorkspace } from '~/stores/workspace'

defineProps<{
  mini?: boolean
  navOpen?: boolean
}>()
defineEmits(['nav-open'])

const tenantConfig = useAppConfig().tenant
const appConfig = useAppConfiguration()
const workspace = useWorkspace()

const isRialticAdmin = computed(() => {
  return (
    workspace.isRialticUser &&
    guard(
      'ANY',
      [PERMISSIONS.readSystem, PERMISSIONS.writeSystem],
      workspace.userPermissions,
    )
  )
})
</script>

<template>
  <div class="flex h-full flex-col">
    <div class="flex-1">
      <div class="sticky top-0 z-10 px-3">
        <div
          v-if="workspace.underMaintenance"
          class="bg-primary-light border-warning mb-6 rounded-lg border border-dashed p-4"
        >
          <h2 class="text-warning-dark">Under Maintenance</h2>
        </div>
        <SelectConnector
          v-if="!workspace.underMaintenance || isRialticAdmin"
          class="bg-primary mb-4 rounded-b-lg"
          :mini="mini && !navOpen"
          primary
          data-testid="selectConnector"
          @click="$emit('nav-open')"
        />
      </div>
      <ul
        class="flex w-full flex-col items-start justify-start space-y-4 px-3 text-neutral-300"
      >
        <AppNavDrawerItem
          v-if="
            workspace.workspaceId &&
            workspace.hasConnectors &&
            !workspace.underMaintenance
          "
          :to="workspace.connectorId === ALL_LOB ? '/dashboard' : '/'"
        >
          <template #icon="{ isActive }">
            <i
              class="inline-block h-full w-6"
              :class="{
                'i-ph-chart-bar': isActive,
                'i-ph-chart-bar-light': !isActive,
              }"
            />
          </template>
          Dashboard
        </AppNavDrawerItem>

        <AuthPermissionsGuard
          :permissions="[PERMISSIONS.readSystem]"
          :workspace-permissions="[
            WORKSPACE_PERMISSION.readSystem,
            WORKSPACE_PERMISSION.writeSystem,
          ]"
        >
          <li class="w-full">
            <AppNavDrawerItem to="/account/api-clients">
              <template #icon="{ isActive }">
                <i
                  class="inline-block h-full w-6"
                  :class="{
                    'i-ph-brackets-curly': isActive,
                    'i-ph-brackets-curly-light': !isActive,
                  }"
                />
              </template>
              API clients
            </AppNavDrawerItem>
          </li>
        </AuthPermissionsGuard>
        <AuthPermissionsGuard
          :permissions="[PERMISSIONS.readUsersAll]"
          :workspace-permissions="[WORKSPACE_PERMISSION.writeWorkspace]"
        >
          <li class="w-full">
            <AppNavDrawerItem to="/account/users">
              <template #icon="{ isActive }">
                <i
                  class="inline-block h-full w-6"
                  :class="{
                    'i-ph-users': isActive,
                    'i-ph-users-light': !isActive,
                  }"
                />
              </template>
              Users
            </AppNavDrawerItem>
          </li>
        </AuthPermissionsGuard>

        <AuthPermissionsGuard
          v-if="!tenantConfig.hideAdminNavLinks"
          :permissions="[
            PERMISSIONS.readUsersAll,
            PERMISSIONS.readWorkspacesAll,
          ]"
        >
          <div class="mt-20 h-px w-full bg-neutral-200"></div>
          <AuthPermissionsGuard :permissions="[PERMISSIONS.readWorkspacesAll]">
            <li class="w-full">
              <AppNavDrawerItem to="/admin">
                <template #icon="{ isActive }">
                  <i
                    class="inline-block h-full w-6"
                    :class="{
                      'i-ph-squares-four': isActive,
                      'i-ph-squares-four-light': !isActive,
                    }"
                  />
                </template>
                Workspaces
              </AppNavDrawerItem>
            </li>
          </AuthPermissionsGuard>
          <AuthPermissionsGuard :permissions="[PERMISSIONS.readUsersAll]">
            <li class="w-full">
              <AppNavDrawerItem to="/admin/users">
                <template #icon="{ isActive }">
                  <i
                    class="inline-block h-full w-6"
                    :class="{
                      'i-ph-user-list': isActive,
                      'i-ph-user-list-light': !isActive,
                    }"
                  />
                </template>
                All users
              </AppNavDrawerItem>
            </li>
          </AuthPermissionsGuard>
        </AuthPermissionsGuard>
      </ul>
    </div>
    <ul v-if="appConfig.feature_zendesk_link" class="nav-ul sticky bottom-0">
      <li class="w-full">
        <AppNavDrawerItem
          to="https://rialtic.zendesk.com/hc/en-us"
          target="_blank"
        >
          <template #icon="{ isActive }">
            <i
              class="inline-block h-full w-6"
              :class="{
                'i-ph-notebook': isActive,
                'i-ph-notebook-light': !isActive,
              }"
            />
          </template>
          <span class="flex items-center justify-between space-x-2">
            Product Guide
            <i
              class="i-ph-arrow-square-out -mt-[2px] ml-2 inline-block h-4 w-4"
            />
          </span>
        </AppNavDrawerItem>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.nav-ul {
  @apply flex w-full flex-col items-start justify-start space-y-4 px-3 text-neutral-300;
}
</style>
